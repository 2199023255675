@import "@design";

.cal-cell {
  $w: 100% / 15;

  position: relative;
  flex: 0 0 $w;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-align: center;
  border-left: 1px solid #c3c3c3;
  border-top: 1px solid #c3c3c3;

  &.last-cell {
    border-right: 1px solid #c3c3c3;
  }
}
.shift-cell {
  padding: 1px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;

  .location-item.sortable-ghost {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  &.weekend-shift {
    background-color: #e3e3e3;
  }
  &.sunday-shift {
    flex: 0 1 50px;
    min-width: 50px;
  }
  &.inactive-foreman {
    background-color: #c6c6c6 !important;
  }
  &.shift-count-1 {
    .shift {
      flex-basis: 100%;
    }
  }
  &.shift-count-2 {
    .shift {
      flex-basis: 50%;
    }
  }
  &.shift-count-3 {
    min-height: 160px;
    .shift {
      flex-basis: 33%;
    }
  }
  .shift:not(:last-child) {
    @media print {
      border-bottom: 1px solid #9e9e9e;
      border-radius: 0 !important;
    }
  }

  @media print {
    &.cal-cell {
      border-left-color: hsl(0, 0, 85%) !important;
      border-right-color: hsl(0, 0, 85%) !important;
      border-top: 1px dashed hsl(0, 0, 20%) !important;
    }
  }
} // .shift-cell
.crew-calendar.dragging .cal-cell {
  &.drop-zone {
    $drop-zone: #defadb;

    background-color: $drop-zone;
    border-color: darken($drop-zone, 20%);
    &.weekend-shift {
      background-color: darken($drop-zone, 7%);
    }
    &.drag-start-zone {
      $drag-start-zone: #f5f5ff;

      background-color: $drag-start-zone;
      border-color: darken($drag-start-zone, 20%);
      &.weekend-shift {
        background-color: darken($drag-start-zone, 7%);
      }
    }
  }
  &.illegal-zone {
    $illegal-zone: #f9e0e0;

    background-color: $illegal-zone;
    border-color: darken($illegal-zone, 20%);
    &.weekend-shift {
      background-color: darken($illegal-zone, 7%);
    }
    &.good-ol-days {
      $good-ol-days: #f7eafe;

      background-color: $good-ol-days;
      border-color: darken($good-ol-days, 10%);
      &.weekend-shift {
        background-color: darken($good-ol-days, 7%);
        border-color: darken($good-ol-days, 12%);
      }
    }
  }
}
